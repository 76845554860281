.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
/* .-z-1 {z-index: -1;} */
.border-50 {
  border-radius: 50%;
}
.origin-0 {
  transform-origin: 0%;
}
.appearance-none {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
}
.menu-transition {
  transform-origin: top center;
}
input:focus ~ label,
input:not(:placeholder-shown) ~ label,
textarea:focus ~ label,
textarea:not(:placeholder-shown) ~ label,
select:focus ~ label,
select:not([value='']):valid ~ label,
input:disabled ~ label,
select:disabled ~ label {
  --tw-translate-x: 0.25rem;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
  --tw-translate-y: -1rem;
}

input:focus ~ label,
select:focus ~ label {
  --tw-text-opacity: 1;
  left: 0px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.bg-caret_down {
  background: url('../public/images/sprites.png') -5px -5px;
  width: 26px;
  height: 26px;
}
.bg-close {
  background: url('../public/images/sprites.png') -41px -5px;
  width: 26px;
  height: 26px;
}
.bg-copy {
  background: url('../public/images/sprites.png') -77px -5px;
  width: 26px;
  height: 26px;
}
.bg-delete {
  background: url('../public/images/sprites.png') -113px -5px;
  width: 26px;
  height: 26px;
}
.bg-add {
  background: url('../public/images/sprites.png') -41px -5px;
  width: 26px;
  height: 26px;
  transform: rotate(90deg);
}
.bg-edit {
  background: url('../public/images/sprites.png') -149px -5px;
  width: 26px;
  height: 26px;
}
.bg-search {
  background: url('../public/images/sprites.png') -185px -5px;
  width: 26px;
  height: 26px;
}
.bg-view {
  background: url('../public/images/sprites.png') -221px -5px;
  width: 26px;
  height: 26px;
}
.bg-drag {
  background: url('../public/images/sprites.png') -257px -5px;
  width: 26px;
  height: 26px;
}
.bg-tick {
  background: url('../public/images/sprites.png') 34px -5px;
  width: 26px;
  height: 26px;
}
.react-tel-input .form-control {
  width: 100%;
  padding: 14.5px 14px 14.5px 60px;
}

.date-override .date-input {
  padding: 1.5rem 1rem 0.25rem 1rem;
  background-color: transparent;
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-width: 1px;
  border-radius: 0.375rem;
  display: block;
  margin-top: 1rem;
}

.delete-date:hover span,
.add-date:hover span {
  display: block;
}

.schedule-table .react-datepicker {
  width: 328px;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  padding: 1.5rem 1rem 0.25rem 1rem;
  background-color: transparent;
  border: 1px solid #e5e7eb;
  border-bottom-width: 2px;
  border-radius: 0.375rem;
  margin-top: 16px;
}

.tree:not(:empty):before,
.tree ul:not(:empty):before,
.tree ul:not(:empty):after,
.tree li:not(:empty):before,
.tree li:not(:empty):after {
  display: block;
  position: absolute;
  content: '';
}
.tree.horizontal ul {
  position: relative;
}
.tree.horizontal li {
  display: flex;
  align-items: center;
  margin-left: 50px;
  position: relative;
  border-left: 1px solid rgba(209, 213, 219, 1);
}
.tree.horizontal li.course {
  border-left: none !important;
}
.tree.horizontal li div {
  margin: 6px 0;
  margin-left: 29px;
  width: 120px;
  text-align: center;
}
.tree.horizontal li:before {
  border-left: 1px solid rgba(5, 150, 105, 1);
  height: 100%;
  width: 0;
  top: 0;
  left: -12px;
}
.tree.horizontal li:first-child:before {
  height: 50%;
  top: 50%;
}
.tree.horizontal li:last-child:before {
  height: 50%;
  bottom: 50%;
  top: auto;
}
.tree.horizontal li:after,
.tree.horizontal li ul:after {
  border-top: 1px solid rgba(5, 150, 105, 1);
  height: 0;
  width: 40px;
  top: 50%;
  left: -12px;
}
.tree.horizontal li:only-child:before {
  content: none;
}
.tree.horizontal li ul:after {
  left: 0;
}
.tree.horizontal > li:only-child {
  margin-left: 0;
}
.tree.horizontal > li:only-child:before,
.tree.horizontal > li:only-child:after {
  content: none;
}
.table-section {
  /* overflow-x: auto; */
}
.tooltip:hover span.tool {
  display: block;
}
th[class^='MuiTableCell-root'] {
  padding: 5px 0 5px 20px !important;
}
button.peer {
  padding-top: 49px;
}
button.peer span:first-child {
  top: 24px;
  left: 16px;
  font-size: 16px;
}
button.peer .place-items-center {
  right: 7px;
  top: 17px;
}
label.pointer-events-none.absolute.font-normal {
  top: 16px;
  left: 15px;
  font-size: 16px;
}
button.peer[aria-hidden='true'] ~ label,
button.peer:focus ~ label,
button.peer:active ~ label,
button.peer:valid ~ label,
button.peer span[text] ~ label,
button.peer:disabled ~ label,
button.peer.focus-label ~ label {
  top: 4px !important;
  left: 15px !important;
  font-size: 12px !important;
}
ul.w-full.max-h-96.bg-white {
  border-radius: none !important;
  padding: 0;
  max-height: 200px;
  z-index: 9 !important;
}
ul.w-full.max-h-96.bg-white li {
  border-bottom: 1px solid #f6f6f6;
  padding: 10px 15px;
}
ul.w-full.max-h-96.bg-white li:hover {
  background: rgb(30 58 138 /1);
  color: #fff;
}
.autocomplete > .active,
.autocomplete li:hover {
  background-color: rgba(30, green, blue, alpha);
  cursor: pointer;
  font-weight: 700;
}
.autocomplete li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}
